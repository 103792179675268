

.errorM{
    margin-right: 0;
}

@media (max-width: 752px){
    .errorM{
        margin-right: 5px;
    }
}