/* body{ 
    border: 2px solid #f6f7f8;
   padding: 100px; 
 } */
 
 .tittle {
    font-size: 24px !important;
}



.landing-partner {
  width: auto;
  position: relative;
  display: block;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 90vh; */
  height: auto;
  min-height: 100vh;
}

  
  @-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }
  @media(max-width: 1170px){
    .tittle {
        font-size: 17px !important;
    }
   
  } 
  @media(max-width: 768px){
    .tittle {
        font-size: 17px !important;

    }
  }
 
  