.padding {
  padding-left: 6px !important;
}
.inner_footer {
}
.inner_footer2 {
}

@media (max-width: 1170px) {
  .padding {
    padding-left: 9px !important;
  }
  .inner_footer {
    padding-top: 540px;
  }
  .inner_footer2 {
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .padding {
    padding-left: 9px !important;
  }
  .inner_footer {
    padding-top: 540px;
  }
  .inner_footer2 {
    padding-top: 100px;
  }
}
