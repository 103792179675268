.header {
    position: fixed;
    width: 100%;
    z-index: 999;
}
.headerText {
    font-size: 22px;
    color: white;
}

.userLogo{
    right: 5%;
    position: absolute;
}

.logoutIcon{
    right: 1%;
    position: absolute;
}
.mobile-header .logo {
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
}